export const accountActionTypes = {
  IMPORT_ACCOUNT: 'ACCOUNT.IMPORT_ACCOUNT',
  CLEAR_ACCOUNT: 'ACCOUNT.CLEAR_ACCOUNT',
};

export function importAccount(address: string, wallet: any, type: string, chainId?: number) {
  return {
    type: accountActionTypes.IMPORT_ACCOUNT,
    payload: {address, wallet, type, chainId},
  };
}

export function clearAccount() {
  return {
    type: accountActionTypes.CLEAR_ACCOUNT,
  };
}
