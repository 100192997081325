import React, {useEffect, useState} from 'react';
import {modalService} from 'src/app/components/commons/ModalListener';
import ImportModal from 'src/app/components/account/ImportModal';
import Lottie from 'react-lottie';
import * as step1Json from 'src/assets/jsons/winning.json';
import * as loadingJson from 'src/assets/jsons/cube-loader.json';
import metamaskLogo from 'src/assets/images/logos/metamask.svg';
import walletConnect from 'src/assets/images/logos/wallet-connect.svg';
import walletLink from 'src/assets/images/logos/wallet-link.svg';
import {formatAddress, formatBigNumber, getAnimatedJsonOptions, displayFormattedNumber, toHex} from 'src/app/utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {getWithdrawTxObject, fetchContractBalance, getTransferOwnershipTxObject} from 'src/app/services/web3/Web3Service';
import {fetchContracts} from 'src/app/services/web3/apiService';
import {WALLET_TYPES} from 'src/app/configs/constants';
import {clearAccount} from 'src/app/actions/accountAction';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import {setContracts, setSelectedContract} from 'src/app/actions/globalAction';
import {ContractType} from 'src/app/types/tx-type';
import {getSupportedNetwork} from 'src/app/configs/env';

export default function AdminAction() {
  const dispatch = useDispatch();

  const {address, wallet, type, chainId} = useSelector((state: any) => state.account);
  const {contracts, selectedContract} = useSelector((state: any) => state.global);

  const [isLoading, setIsLoading] = useState(false);
  const [contractBalance, setContractBalance] = useState(0);
  const [updateRootTxHash, setUpdateRootTxHash] = useState('');
  const [withDrawTxHash, setwithDrawTxHash] = useState('');
  const [transferOwnerTxHash, setTransferOwnerTxHash] = useState('');
  const [newMerkleRoot, setNewMerkleRoot] = useState('');
  // const [owner, setOwner] = useState('');
  const [newOwner, setNewOwner] = useState('');
  const [ENV, setENV] = useState<any>();

  useEffect(() => {
    if (!chainId) return;
    const env: any = getSupportedNetwork(chainId);
    setENV(env);
    setupContracts();
  }, [chainId]); // eslint-disable-line

  useEffect(() => {
    if (!address || !selectedContract) return;
    setupData();
  }, [address, selectedContract]); // eslint-disable-line

  async function setupContracts() {
    const result = await fetchContracts(chainId);
    if (result.length > 0) {
      dispatch(setContracts(result));

      if (!selectedContract) {
        dispatch(setSelectedContract(result[0]));
      }
    }
  }

  async function setupData() {
    try {
      const contractBalance = await fetchContractBalance(selectedContract.contract, chainId);
      const contractBalanceAmount = +formatBigNumber(contractBalance);
      setContractBalance(contractBalanceAmount);

      // const ownerAdd = await getOwner(selectedContract.contract);
      // setOwner(ownerAdd);
    } catch (e: any) {
      console.log(e.message);
    }
  }

  function openImportModal() {
    modalService.show(ImportModal);
  }

  function disconnect() {
    dispatch(clearAccount());
    setIsLoading(false);
  }

  async function withdraw() {
    const amount = toHex((contractBalance - 0.1) * Math.pow(10, 18));
    try {
      const updateRootTx = getWithdrawTxObject(address, amount, selectedContract ? selectedContract.contract : '', chainId);
      const txHash = await wallet.makeTransaction(updateRootTx);
      setwithDrawTxHash(txHash);
    } catch (e) {
      console.log(e);
    }
  }

  async function transferOwner() {
    try {
      const updateRootTx = getTransferOwnershipTxObject(address, newOwner, selectedContract ? selectedContract.contract : '');
      const txHash = await wallet.makeTransaction(updateRootTx);
      setTransferOwnerTxHash(txHash);
    } catch (e) {
      console.log(e);
    }
  }

  function getWalletImage() {
    if (type === WALLET_TYPES.WALLET_CONNECT) {
      return walletConnect;
    } else if (type === WALLET_TYPES.WALLET_LINK) {
      return walletLink;
    } else {
      return metamaskLogo;
    }
  }

  function handleChangeContract(event: React.ChangeEvent<{value: any}>) {
    const contractAddr = event.target.value;
    const selectedContract = contracts.find((contract: ContractType) => {
      return contract.contract === contractAddr;
    });
    dispatch(setSelectedContract(selectedContract));
  }

  function handleChangeMerkleRoot(event: React.ChangeEvent<{value: any}>) {
    const merkleRoot = event.target.value;
    setNewMerkleRoot(merkleRoot);
  }

  function handleChangeOwner(event: React.ChangeEvent<{value: any}>) {
    const newOwner = event.target.value;
    setNewOwner(newOwner);
  }

  return (
    <div className="wrapper">
      <div className="home">
        <div className="home__title mb-2">Withdraw</div>
        {!address && (
          <div className="slide-up">
            <Lottie height={220} width={220} isClickToPauseDisabled={true} options={getAnimatedJsonOptions(step1Json)} style={{margin: '0px auto 35px'}} />
          </div>
        )}

        {address && (
          <div className="fade-in">
            <div className="home__address">
              <img style={{width: 16}} src={getWalletImage()} alt="wallet" />
              <span>{formatAddress(address, 8, -6)}</span>
            </div>
            <div className="home__disconnect" onClick={disconnect}>
              Disconnect
            </div>

            <div className="mt-5" style={{fontSize: 12, fontWeight: 500}}>
              <div>Select the contract you want to interact with</div>
              <FormControl className="mt-2" style={{width: '50%', borderRadius: '10px'}}>
                <Select labelId="vesting-round" value={selectedContract.contract} onChange={handleChangeContract}>
                  {contracts.map((contract: any, index: number) => {
                    return (
                      <MenuItem value={contract.contract} key={index}>
                        {contract.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            {withDrawTxHash !== '' && (
              <div className="mt-5 slide-up">
                <div className="mb-2 fw-medium">Tx Hash:</div>
                <a className="home__link" href={`${ENV?.URLS.ETHERSCAN}/tx/${withDrawTxHash}`} target="_blank" rel="noreferrer noopener">
                  {formatAddress(withDrawTxHash, 10, -8)}
                </a>
              </div>
            )}

            {isLoading && (
              <div>
                <Lottie height={150} width={220} style={{marginBottom: '50px'}} isClickToPauseDisabled={true} options={getAnimatedJsonOptions(loadingJson)} />
              </div>
            )}

            {!isLoading && (
              <div className="fade-in">
                <div className="mt-6 mb-2">
                  <span className="fw-medium mb-1 mr-1">Balance:</span>
                  <b>{displayFormattedNumber(contractBalance, 4)} RIFI</b>
                </div>
              </div>
            )}
          </div>
        )}

        <div className={`btn btn--gradient`} onClick={address ? withdraw : openImportModal}>
          {isLoading && <div>Loading...</div>}
          <div>{address ? 'Withdraw' : 'Connect Wallet'}</div>
        </div>
      </div>

      <div className="home">
        <div className="home__title mb-2">TransferOwnership</div>

        {!address && (
          <div className="slide-up">
            <Lottie height={220} width={220} isClickToPauseDisabled={true} options={getAnimatedJsonOptions(step1Json)} style={{margin: '0px auto 35px'}} />
          </div>
        )}

        {address && (
          <div className="fade-in">
            <div className="home__address">
              <img style={{width: 16}} src={getWalletImage()} alt="wallet" />
              <span>{formatAddress(address, 8, -6)}</span>
            </div>
            <div className="home__disconnect" onClick={disconnect}>
              Disconnect
            </div>

            <div className="mt-5" style={{fontSize: 12, fontWeight: 500}}>
              <div>Select the contract you want to interact with</div>
              <FormControl className="mt-2" style={{width: '50%', borderRadius: '10px'}}>
                <Select labelId="vesting-round" value={selectedContract.contract} onChange={handleChangeContract}>
                  {contracts.map((contract: any, index: number) => {
                    return (
                      <MenuItem value={contract.contract} key={index}>
                        {contract.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            {transferOwnerTxHash !== '' && (
              <div className="mt-5 slide-up">
                <div className="mb-2 fw-medium">Tx Hash:</div>
                <a className="home__link" href={`${ENV?.URLS.ETHERSCAN}/tx/${transferOwnerTxHash}`} target="_blank" rel="noreferrer noopener">
                  {formatAddress(transferOwnerTxHash, 10, -8)}
                </a>
              </div>
            )}

            {isLoading && (
              <div>
                <Lottie height={150} width={220} style={{marginBottom: '50px'}} isClickToPauseDisabled={true} options={getAnimatedJsonOptions(loadingJson)} />
              </div>
            )}

            {!isLoading && (
              <div className="fade-in">
                <div className="mt-6 mb-2">
                  <TextField value={newOwner} label="New Owner" variant="outlined" fullWidth onChange={handleChangeOwner} />
                </div>
              </div>
            )}
          </div>
        )}

        <div className={`btn btn--gradient`} onClick={address ? transferOwner : openImportModal}>
          {isLoading && <div>Loading...</div>}
          <div>{address ? 'Transfer' : 'Connect Wallet'}</div>
        </div>
      </div>
    </div>
  );
}
