const {SupportedEnvNetwork} = require('./constants');
const environment = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'smartChainTestnet';
const ENV = require(`./envs/${environment}`).default;

const getSupportedNetwork = (chainId) => {
  return SupportedEnvNetwork[chainId];
};

const getEnvNetwork = () => (process.env.REACT_APP_ENV ? [56] : [97]);

module.exports = {ENV, getSupportedNetwork, getEnvNetwork};
