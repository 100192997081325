const env = {
  NETWORK_ID: 56,
  CHAIN_NAME: 'Binance Smart Chain',
  URLS: {
    ETHERSCAN: 'https://bscscan.com',
    API_SERVER: 'http://localhost:8080',
  },
  NODE: {
    URL: 'https://bsc-dataseed.binance.org/',
    CONNECTION_TIMEOUT: 12000,
  },
  TOKEN: {
    ADDRESS: '0xe17fbdf671f3cce0f354cacbd27e03f4245a3ffe',
  },
};

export default env;
