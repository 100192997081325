const env = {
  NETWORK_ID: 97,
  CHAIN_NAME: 'Smart Chain Test Network',
  URLS: {
    ETHERSCAN: 'https://testnet.bscscan.com',
    API_SERVER: 'http://localhost:8080',
  },
  NODE: {
    URL: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    CONNECTION_TIMEOUT: 1200,
  },
  TOKEN: {
    ADDRESS: '0x3816b86abed8b7f69d8ab4b7a9b5eb866c0b0f18',
  },
};

export default env;
