import {SupportedChainId} from './constants';

export const TokenLockersNetWork = {
  [SupportedChainId.BSC]: [
    {
      name: 'Influencer',
      // contract: '0x76097351F19c61ebd3B163C29964175d5DE92BB7',//testnet
      contract: '0x261f4731b4080cA4652D0f1A04eA055987bd2F98', //mainnet
    },
    {
      name: 'Strategic Round',
      contract: '0xFC6dF58a723323e46918B04F27bfDF4B5dFbDC23', //mainnet
    },
  ],
};
