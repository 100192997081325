const env = {
  NETWORK_ID: 137,
  CHAIN_NAME: 'Polygon',
  URLS: {
    ETHERSCAN: 'https://polygonscan.com/',
    API_SERVER: 'http://localhost:8080',
  },
  NODE: {
    URL: 'https://polygon-rpc.com',
    CONNECTION_TIMEOUT: 12000,
  },
  TOKEN: {
    ADDRESS: '',
  },
};

export default env;
