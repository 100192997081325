import smartChain from './envs/smartChain';
import smartChainTestnet from './envs/smartChainTestnet';
import polygon from './envs/polygon';
import mumbai from './envs/mumbai';
export const ABI = {
  ERC20: [
    {constant: true, inputs: [], name: 'name', outputs: [{name: '', type: 'string'}], payable: false, type: 'function'},
    {constant: true, inputs: [], name: 'minter', outputs: [{name: '', type: 'address'}], payable: false, type: 'function'},
    {
      constant: false,
      inputs: [
        {name: '_spender', type: 'address'},
        {name: '_value', type: 'uint256'},
      ],
      name: 'approve',
      outputs: [{name: 'o_success', type: 'bool'}],
      payable: false,
      type: 'function',
    },
    {constant: true, inputs: [], name: 'totalSupply', outputs: [{name: '', type: 'uint256'}], payable: false, type: 'function'},
    {
      constant: false,
      inputs: [
        {name: '_recipient', type: 'address'},
        {name: '_value', type: 'uint256'},
      ],
      name: 'createIlliquidToken',
      outputs: [{name: 'o_success', type: 'bool'}],
      payable: false,
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        {name: '_from', type: 'address'},
        {name: '_recipient', type: 'address'},
        {name: '_amount', type: 'uint256'},
      ],
      name: 'transferFrom',
      outputs: [{name: 'o_success', type: 'bool'}],
      payable: false,
      type: 'function',
    },
    {constant: true, inputs: [], name: 'endMintingTime', outputs: [{name: '', type: 'uint256'}], payable: false, type: 'function'},
    {constant: true, inputs: [], name: 'decimals', outputs: [{name: '', type: 'uint256'}], payable: false, type: 'function'},
    {
      constant: false,
      inputs: [
        {name: '_recipient', type: 'address'},
        {name: '_value', type: 'uint256'},
      ],
      name: 'createToken',
      outputs: [{name: 'o_success', type: 'bool'}],
      payable: false,
      type: 'function',
    },
    {constant: true, inputs: [{name: '_owner', type: 'address'}], name: 'balanceOf', outputs: [{name: 'balance', type: 'uint256'}], payable: false, type: 'function'},
    {constant: true, inputs: [{name: '', type: 'address'}], name: 'illiquidBalance', outputs: [{name: '', type: 'uint256'}], payable: false, type: 'function'},
    {constant: true, inputs: [], name: 'symbol', outputs: [{name: '', type: 'string'}], payable: false, type: 'function'},
    {
      constant: false,
      inputs: [
        {name: '_recipient', type: 'address'},
        {name: '_amount', type: 'uint256'},
      ],
      name: 'transfer',
      outputs: [{name: 'o_success', type: 'bool'}],
      payable: false,
      type: 'function',
    },
    {constant: true, inputs: [], name: 'LOCKOUT_PERIOD', outputs: [{name: '', type: 'uint256'}], payable: false, type: 'function'},
    {
      constant: true,
      inputs: [
        {name: '_owner', type: 'address'},
        {name: '_spender', type: 'address'},
      ],
      name: 'allowance',
      outputs: [{name: 'o_remaining', type: 'uint256'}],
      payable: false,
      type: 'function',
    },
    {constant: false, inputs: [], name: 'makeLiquid', outputs: [], payable: false, type: 'function'},
    {
      inputs: [
        {name: '_minter', type: 'address'},
        {name: '_endMintingTime', type: 'uint256'},
      ],
      payable: false,
      type: 'constructor',
    },
    {
      anonymous: false,
      inputs: [
        {indexed: true, name: '_from', type: 'address'},
        {indexed: true, name: '_recipient', type: 'address'},
        {indexed: false, name: '_value', type: 'uint256'},
      ],
      name: 'Transfer',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {indexed: true, name: '_owner', type: 'address'},
        {indexed: true, name: '_spender', type: 'address'},
        {indexed: false, name: '_value', type: 'uint256'},
      ],
      name: 'Approval',
      type: 'event',
    },
  ],
};
export const ROUTES = {
  HOME: '/',
  // ADMIN: '/admin',
  ADMINACTION: '/admin/action',
};
export const WALLET_TYPES = {
  METAMASK: 'metamask',
  WALLET_CONNECT: 'wallet connect',
  WALLET_LINK: 'wallet link',
  DAPP: 'dapp',
};
export const ACTIONS = {
  APPROVE: 1,
};
export const DEFAULT_GAS_LIMIT = {
  APPROVE: 100000,
  APPROVE_MAX: 70000,
};
export const TX_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
};
export const MOBILE_SCREEN_SIZE = 700;

export const SupportedChainId = process.env.REACT_APP_ENV
  ? {
      BSC: 56
    }
  : {
      BSC: 97
    };

export const SupportedEnvNetwork = process.env.REACT_APP_ENV
  ? {
      [SupportedChainId.BSC]: smartChain
    }
  : {
      [SupportedChainId.BSC]: smartChainTestnet
    };
