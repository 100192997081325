import React from 'react';
import {Route} from 'react-router-dom';
import logo from '../../../assets/images/rikkei-finance.svg';

interface LayoutProps {
  component: React.ComponentType<any>;
  path: string;
  exact: boolean;
}

export default function Layout({component: Component, ...rest}: LayoutProps) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <div className="layout">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          {/* <div className='decor decor--top-left'/>
        <div className='decor decor--bot-left'/>
        <div className='decor decor--bot-right'/> */}
          <Component {...props} />
        </div>
      )}
    />
  );
}
