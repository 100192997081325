import React, {useEffect, useRef, useState} from 'react';
import {modalService} from 'src/app/components/commons/ModalListener';
import ImportModal from 'src/app/components/account/ImportModal';
import Lottie from 'react-lottie';
import * as step1Json from 'src/assets/jsons/winning.json';
import * as loadingJson from 'src/assets/jsons/cube-loader.json';
import metamaskLogo from 'src/assets/images/logos/metamask.svg';
import walletConnect from 'src/assets/images/logos/wallet-connect.svg';
import walletLink from 'src/assets/images/logos/wallet-link.svg';
import {formatAddress, formatBigNumber, getAnimatedJsonOptions, displayFormattedNumber} from 'src/app/utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {fetchClaimableAmount, fetchLockedAmount, fetchReleasedAmount, getUnlockTxObject, fetchContractBalance} from 'src/app/services/web3/Web3Service';
import {fetchContracts} from 'src/app/services/web3/apiService';
import {WALLET_TYPES} from 'src/app/configs/constants';
import {clearAccount} from 'src/app/actions/accountAction';
import {getSupportedNetwork} from 'src/app/configs/env';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {setContracts, setSelectedContract} from 'src/app/actions/globalAction';
import {ContractType} from 'src/app/types/tx-type';
import coverImg from 'src/assets/images/rikkei-finance.svg';
import CountUp from 'react-countup';

export default function Home() {
  const dispatch = useDispatch();

  const {address, wallet, type, chainId} = useSelector((state: any) => state.account);
  const {contracts, selectedContract} = useSelector((state: any) => state.global);

  const [isLoading, setIsLoading] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [claimableAmount, setClaimableAmount] = useState(0);
  const [claimedAmount, setClaimedAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [latestTxHash, setLatestTxHash] = useState('');
  const [ENV, setENV] = useState<any>();
  const lastClaimableAmount = useRef<any>();
  const lastClaimedAmount = useRef<any>();

  useEffect(() => {
    if (!chainId) return;
    const env: any = getSupportedNetwork(chainId);
    setENV(env);
    reset();
    setupContracts();
  }, [address, chainId]); // eslint-disable-line

  useEffect(() => {
    if (!address || !selectedContract || !selectedContract?.contract) return;
    setupData(address, selectedContract.contract);
    const interval = setInterval(() => setupData(address, selectedContract.contract), 5 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [address, selectedContract, dispatch]);

  useEffect(() => {
    lastClaimableAmount.current = claimableAmount;
  }, [claimableAmount]);

  useEffect(() => {
    lastClaimedAmount.current = claimedAmount;
  }, [claimedAmount]);

  async function setupContracts() {
    const result = await fetchContracts(chainId);
    if (result.length > 0) {
      dispatch(setContracts(result));
      dispatch(setSelectedContract(result[0]));
    }
  }

  async function setupData(address: string, contractAddress: string) {
    // setIsLoading(true);
    try {
      const lockedAmount: number = await fetchLockedAmount(address, contractAddress);
      let total = 0;
      let claimed, claimable;

      if (!lockedAmount) {
        setTotalAmount(0);
        setClaimedAmount(0);
        setClaimableAmount(0);

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);

        return;
      }

      if (lockedAmount === 0) {
        claimed = 0;
        claimable = 0;
      } else {
        total = lockedAmount;
        claimed = await fetchReleasedAmount(address, contractAddress);
        claimable = await fetchClaimableAmount(address, contractAddress);
      }

      setTotalAmount(+formatBigNumber(total));
      setClaimedAmount(+formatBigNumber(claimed));
      setClaimableAmount(+formatBigNumber(claimable));
    } catch (e: any) {
      console.log(e);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  function openImportModal() {
    modalService.show(ImportModal);
  }

  function disconnect() {
    dispatch(clearAccount());
    setIsLoading(false);
  }

  function reset() {
    setTotalAmount(0);
    setClaimedAmount(0);
    setClaimableAmount(0);
    setLatestTxHash('');
  }

  async function claim() {
    if (isLoading) return;
    console.log('Claim', chainId);
    const contractBalance = await fetchContractBalance(selectedContract.contract, chainId);
    const contractBalanceAmount = +formatBigNumber(contractBalance);

    if (contractBalanceAmount < claimableAmount) {
      alert(`The claiming contract runs out of RIFI, please contact the administrators.`);
    } else {
      setIsClaiming(true);

      try {
        let claimTx;

        claimTx = getUnlockTxObject(address, address, selectedContract ? selectedContract.contract : '');

        const txHash = await wallet.makeTransaction(claimTx);
        setLatestTxHash(txHash);
        setupData(address, selectedContract.contract);
      } catch (e) {
        console.log(e);
      }

      setIsClaiming(false);
    }
  }

  function getWalletImage() {
    if (type === WALLET_TYPES.WALLET_CONNECT) {
      return walletConnect;
    } else if (type === WALLET_TYPES.WALLET_LINK) {
      return walletLink;
    } else {
      return metamaskLogo;
    }
  }

  function handleChangeContract(event: React.ChangeEvent<{value: any}>) {
    const contractAddr = event.target.value;
    const selectedContract = contracts.find((contract: ContractType) => {
      return contract.contract === contractAddr;
    });
    dispatch(setSelectedContract(selectedContract));
  }

  return (
    <div className="home">
      <div className="home__title mb-2">RIFI Distribution Portal</div>

      {!address && (
        <div className="slide-up">
          <img height={220} width={220} style={{margin: '0px auto 35px'}} src={coverImg} alt="cover" />
          {/* <Lottie height={220} width={220} isClickToPauseDisabled={true} options={getAnimatedJsonOptions(step1Json)} style={{margin: '0px auto 35px'}} /> */}
          <div className="home__subtitle">Claim Your Vesting</div>
          <div className="home__desc">Let us detect if you have any unclaimed RIFI in influencer round by connecting to your wallet first.</div>
        </div>
      )}

      {address && (
        <div className="fade-in">
          <div className="home__address">
            <img style={{width: 16}} src={getWalletImage()} alt="wallet" />
            <span>{formatAddress(address, 8, -6)}</span>
          </div>
          <div className="home__disconnect" onClick={disconnect}>
            Disconnect
          </div>

          <div className="mt-5" style={{fontSize: 12, fontWeight: 500}}>
            <div>Vesting Round</div>
            <FormControl className="mt-2" style={{width: '50%', borderRadius: '10px'}}>
              <Select labelId="vesting-round" value={selectedContract.contract || ''} onChange={handleChangeContract} disabled={contracts?.length < 2}>
                {contracts.map((contract: any, index: number) => {
                  return (
                    <MenuItem value={contract.contract} key={index}>
                      {contract.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          {latestTxHash !== '' && (
            <div className="mt-5 slide-up">
              <div className="mb-2 fw-medium">Tx Hash:</div>
              <a className="home__link" href={`${ENV?.URLS.ETHERSCAN}/tx/${latestTxHash}`} target="_blank" rel="noreferrer noopener">
                {formatAddress(latestTxHash, 10, -8)}
              </a>
            </div>
          )}

          {isLoading && (
            <div>
              <Lottie height={150} width={220} style={{marginBottom: '50px'}} isClickToPauseDisabled={true} options={getAnimatedJsonOptions(loadingJson)} />
            </div>
          )}

          {!isLoading && (
            <div className="fade-in">
              <div className="mt-6 mb-2">
                <span className="fw-medium mb-1 mr-1">Available:</span>
                <b>{displayFormattedNumber(totalAmount - claimedAmount, 4)} RIFI</b>
              </div>
              <div className="mb-2">
                <span className="fw-medium mb-1 mr-1">Claimed:</span>
                <b>
                  <span>
                    <CountUp start={lastClaimedAmount.current} end={claimedAmount} duration={1.5} separator="," decimals={claimedAmount > 0 && claimedAmount < 1 ? 6 : 4} decimal="." />
                  </span>
                  {` `}
                  RIFI
                </b>
              </div>
              <div className="mb-7">
                <span className="fw-medium mb-1 mr-1">Claimable:</span>
                <b>
                  <span>
                    <CountUp start={lastClaimableAmount.current} end={claimableAmount} duration={1.5} separator="," decimals={claimableAmount > 0 && claimableAmount < 1 ? 6 : 4} decimal="." />
                  </span>
                  {` `}
                  RIFI
                </b>
              </div>
            </div>
          )}
        </div>
      )}

      <div className={`btn btn--gradient ${isLoading || isClaiming ? 'disabled' : ''} ${address && !claimableAmount ? 'disabled' : ''}`} onClick={address ? claim : openImportModal}>
        {(isClaiming || isLoading) && <h1>Loading...</h1>}
        {!isClaiming && !isLoading && <h1>{address ? 'Claim' : 'Connect Wallet'}</h1>}
      </div>
    </div>
  );
}
