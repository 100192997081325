const env = {
  NETWORK_ID: 80001,
  CHAIN_NAME: 'Polygon Mumbai',
  URLS: {
    ETHERSCAN: 'https://mumbai.polygonscan.com',
    API_SERVER: 'http://localhost:8080',
  },
  NODE: {
    URL: 'https://matic-mumbai.chainstacklabs.com',
    CONNECTION_TIMEOUT: 12000,
  },
  TOKEN: {
    ADDRESS: '0xdDF75F66EFe2FDaaa59ead2dB3377139dA25a848',
  },
};

export default env;
